/* eslint-disable complexity */
import {
  isDineInUrl,
  getHqIdFromUrl,
  getRestaurantIdFromUrl,
} from '@chownow/cn-web-utils/url';

export const ROUTES = {
  account: {
    isHqRequired: false,
    path: '/account',
  },
  checkout: {
    isHqRequired: true,
    isRestaurantRequired: true,
    path: '/checkout',
  },
  confirmation: {
    isOrderIdRequired: true,
    path: '',
  },
  history: {
    path: '/history',
  },
  locations: {
    isHqRequired: true,
    path: '/locations',
  },
  membershipCheckout: {
    isHqRequired: true,
    isRestaurantRequired: true,
    path: '/membership/checkout',
  },
  membershipConfirmation: {
    isHqRequired: true,
    isRestaurantRequired: true,
    path: '/membership/confirmation',
  },
  membershipList: {
    isHqRequired: false,
    path: '/membership',
  },
  membershipPlans: {
    isHqRequired: true,
    isRestaurantRequired: true,
    path: '/membership',
  },
  menu: {
    isHqRequired: true,
    isRestaurantRequired: true,
    path: '',
  },
  support: {
    isHqRequired: true,
    path: '/support',
  },
};

export function buildRoute({
  hqId,
  restaurantId,
  route,
  orderId,
  query,
}: {
  hqId?: string;
  restaurantId?: string;
  route: {
    isHqRequired?: boolean;
    isRestaurantRequired?: boolean;
    isOrderIdRequired?: boolean;
    path: string;
  };
  orderId?: string;
  query?: { name: string; value: string };
}): string {
  const { isHqRequired, isRestaurantRequired, isOrderIdRequired, path } = route;
  const isDineIn = isDineInUrl();
  const hasValidQuery = query && query.name && query.value;
  const queryParam = hasValidQuery ? `?${query.name}=${query.value}` : '';

  /* eslint-disable no-nested-ternary */
  return `${isDineIn ? '/dine-in' : '/order'}${isHqRequired ? `/${hqId}` : ''}${
    isRestaurantRequired
      ? `/locations/${restaurantId}`
      : isOrderIdRequired
      ? `/history/${orderId}`
      : ''
  }${path}${queryParam}`;
  /* eslint-enable no-nested-ternary */
}

/**
 * Creates a redirectURL to either a restaurant location
 * or Restaurant HQ
 */
export function getRestaurantRedirectUrl(): string {
  const hqId = getHqIdFromUrl();
  const restaurantId = getRestaurantIdFromUrl();
  return buildRoute({
    hqId,
    restaurantId,
    route: restaurantId ? ROUTES.menu : ROUTES.locations,
  });
}

export function buildAccountRoute({
  query,
}: {
  query?: { name: string; value: string };
}): string {
  const hasValidQuery = query && query.name && query.value;
  const queryParam = hasValidQuery ? `?${query.name}=${query.value}` : '';

  return `${ROUTES.account.path}${queryParam}`;
}
