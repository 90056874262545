import React, { useContext } from 'react';

import { ModalContext } from 'context/ModalContext';

import Button from 'primitives/Button';
import Modal from 'primitives/Modals/Modal';
import ModalFooter from 'primitives/Modals/ModalFooter';
import ModalHeader from 'primitives/Modals/ModalHeader';

import styles from './styles.module.scss';

interface RewardsOptOutModalProps {
  restaurantId: string;
  restaurantName: string;
  handleRewardsOptOut: (restaurantId: string) => void;
}

export default function RewardsOptOutModal({
  restaurantId,
  restaurantName,
  handleRewardsOptOut,
}: RewardsOptOutModalProps) {
  const { closeModal } = useContext(ModalContext);

  function handleOptout() {
    handleRewardsOptOut(restaurantId);
  }

  return (
    <Modal>
      <ModalHeader
        title="Leaving the Rewards Program?"
        subtitle={
          <div className={styles.content}>
            By opting out from{' '}
            <span className={styles.restaurantName}>{restaurantName}</span>{' '}
            rewards program, you&apos;ll lose all your progress and won&apos;t
            be able to earn new stickers or rewards. Do you still want to
            proceed?
          </div>
        }
      />
      <ModalFooter>
        <Button onClick={handleOptout} isFullWidth className={styles.optoutBtn}>
          Yes, opt me out
        </Button>
        <Button onClick={closeModal} variant="outline" isFullWidth>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
