/* eslint-disable complexity */
import React, { useEffect } from 'react';
import classNames from 'classnames';

import { RewardsType, RewardsFromRestaurantType } from 'types/api';
import { MODAL_TYPE } from 'helpers/modals';
import { ANALYTICS_EVENT_NAME, logAnalyticsEvent } from 'helpers/loggers';

import { RingProgress } from '@mantine/core';

import ButtonLink from 'primitives/ButtonLink';
import Switch from 'primitives/Switch';
import AchievedRewardSticker from 'svgs/AchievedRewardSticker';
import Sticker from 'svgs/Sticker';

import styles from './styles.module.scss';

interface RewardsCardProps {
  isLoggedIn: boolean;
  openModal: (arg: string) => void;
  rewards: RewardsType[];
  rewardsFromRestaurant: RewardsFromRestaurantType;
  rewardsOptIn: boolean;
  setRewardsOptIn: () => void;
}

export default function RewardsCard({
  isLoggedIn,
  openModal,
  rewards,
  rewardsFromRestaurant,
  rewardsOptIn,
  setRewardsOptIn,
}: RewardsCardProps) {
  const rewardsInProgress = !!rewards?.[0];

  const ctaCopy = isLoggedIn
    ? 'Opt in to start earning rewards.'
    : 'Log in to start earning rewards.';

  const { progress_cards, opted_in = true } = rewards?.[0] || {};
  const { num_qualified_orders } = progress_cards?.[0] || 0;
  const { rewards_progress } = progress_cards?.[0]?.messages || '';

  const { messages, order_threshold = 1 } = rewardsFromRestaurant;

  const copy = rewardsInProgress
    ? rewards_progress
    : `${rewardsFromRestaurant.messages.program_description} ${ctaCopy}`;

  const progressValue = (num_qualified_orders / order_threshold) * 100;

  const rewardMet = num_qualified_orders === order_threshold;

  const progressRingOrRibbon = rewardMet ? (
    <AchievedRewardSticker />
  ) : (
    <RingProgress
      size={48}
      thickness={4}
      roundCaps
      rootColor="#DAD8D2"
      sections={[{ value: progressValue, color: '#0E7A4A' }]}
    />
  );

  const leftVisualElement = rewardsInProgress ? (
    progressRingOrRibbon
  ) : (
    <Sticker />
  );

  const showOptInSwitch = !rewardsInProgress && isLoggedIn;

  function showRewardsModal() {
    openModal(MODAL_TYPE.rewards, {
      isLoggedIn,
      messages,
      numQualifiedOrders: num_qualified_orders,
      orderThreshold: order_threshold,
      rewardMet,
      rewardsProgress: rewards_progress,
    });

    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.selectX,
      attributes: {
        name: 'learn-more-loyalty',
        source: 'checkout',
      },
    });
  }

  useEffect(() => {
    if (!rewardsOptIn) {
      logAnalyticsEvent({
        eventName: ANALYTICS_EVENT_NAME.selectX,
        attributes: {
          name: 'opt-out-loyalty',
          source: 'checkout',
        },
      });
    }
  }, [rewardsOptIn]);

  // In case of multiple progress cards, do not show rewards card. Discount is reflected in order summary.
  // In case of user opted out, do not show card
  if (progress_cards?.length > 1 || !opted_in) {
    return null;
  }

  return (
    <div className={styles.card}>
      <div className={styles.sticker}>{leftVisualElement}</div>
      <div className={styles.copyWrapper}>
        <div
          className={classNames(styles.copy, {
            [styles.congratsCopy]: rewardMet,
          })}
        >
          {copy}{' '}
          {rewardMet ? (
            'Applied at checkout.'
          ) : (
            <ButtonLink onClick={showRewardsModal} className={styles.copy}>
              Learn More
            </ButtonLink>
          )}
        </div>
      </div>
      {showOptInSwitch && (
        <div className={styles.switch}>
          <Switch
            isToggled={rewardsOptIn}
            onToggle={() => setRewardsOptIn(!rewardsOptIn)}
          />
        </div>
      )}
    </div>
  );
}
