/* eslint-disable complexity */

// ticket to fix the below 3 linting errors here: https://chownow.atlassian.net/browse/CN-23034
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { get } from 'lodash';
import classNames from 'classnames';

import { buildRoute, buildAccountRoute, ROUTES } from 'helpers/routes';
import { KEY_VALUES } from 'helpers/constants';
import { AUTH_MODAL_TYPE, MODAL_TYPE } from 'helpers/modals';
import { isWithin30Days } from 'helpers/date';
import { logAnalyticsEvent, ANALYTICS_EVENT_NAME } from 'helpers/loggers';
import { isOnPage } from '@chownow/cn-web-utils/url';
import { HELP_URL, BASE_CHOWNOW_URL } from '@chownow/cn-web-utils/paths';

import usePlatform from 'hooks/usePlatform';

import { getRestaurantDetails } from 'modules/restaurant';

import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import MembershipBadge from 'components/MembershipBadge';
import SlideInDown from 'components/Animations/SlideInDown';
import CustomerMembershipBanner from 'components/CustomerMembershipBanner';
import Outlink from 'primitives/Outlink';

import { ReactComponent as IconProfile } from 'images/icon-profile.svg';

import styles from './styles.module.scss';

function AuthMenu() {
  // used in the case of Account Settings where hqId is not in URL
  const restaurant = useSelector(getRestaurantDetails);
  const savedHqId = restaurant && restaurant.company_id;

  const { hqId } = useParams();
  const { isMarketplacePlatform } = usePlatform();

  const { openModal } = useContext(ModalContext);
  const {
    isLoggedIn,
    user,
    handleLogout,
    membership = {},
  } = useContext(UserContext);
  const { userMembershipPlan, hasActiveMembership, expiredMemberships } =
    membership;

  const isOnAccountSettings = isOnPage('/account');
  // '/history/' (with the trailing /) would lead to a confirmation page and not order history
  const isOnOrderHistory = isOnPage('/history') && !isOnPage('/history/');

  // only show the membership badge next to user name if they have an active membership,
  // are not in Marketplace and are not on Account Settings or Order History
  const canShowMembershipDisplay =
    hasActiveMembership &&
    !isMarketplacePlatform &&
    !isOnAccountSettings &&
    !isOnOrderHistory;

  // if they have an active membership we want that to show up always, otherwise only show expired
  // membership for 30 days after
  const lastExpiredMembership =
    expiredMemberships && expiredMemberships[expiredMemberships.length - 1];
  const canShowCustomerMembershipBanner =
    (hasActiveMembership ||
      isWithin30Days(get(lastExpiredMembership, 'expires_at'))) &&
    !isMarketplacePlatform &&
    !isOnAccountSettings &&
    !isOnOrderHistory;

  const [isAuthMenuOpen, setIsAuthMenuOpen] = useState(false);

  function toggleDropdown() {
    setIsAuthMenuOpen(!isAuthMenuOpen);
  }

  function handleOpenAuthModal(modalType) {
    openModal(MODAL_TYPE.auth, { activeAuthModal: modalType });

    if (isAuthMenuOpen) setIsAuthMenuOpen(false);
  }

  function handleAuthBtnClick() {
    return isMarketplacePlatform || isLoggedIn
      ? toggleDropdown()
      : handleOpenAuthModal(AUTH_MODAL_TYPE.loginSignUp);
  }

  function handleClickLogout() {
    handleLogout();
    setIsAuthMenuOpen(false);
  }

  function handleSelectRenewMembership() {
    setIsAuthMenuOpen(false);
  }

  function onSupportClick() {
    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.selectCustomerSupport,
      attributes: {
        source: 'auth-menu',
      },
    });
    // flushes the event queue and forces the event to send to mParticle
    // only needed when linking out to prevent CORS error
    window.mParticle.upload();
  }

  return (
    <div className={styles.authMenu}>
      <button
        onClick={handleAuthBtnClick}
        className={classNames(styles.btnAuth, {
          [styles.authOpen]: isAuthMenuOpen,
        })}
        data-testid="LoginAndUser"
        aria-expanded={setIsAuthMenuOpen}
        type="button"
      >
        {canShowMembershipDisplay ? (
          <MembershipBadge isAuthMenu userMembershipPlan={userMembershipPlan} />
        ) : (
          <IconProfile className={styles.iconProfile} aria-hidden="true" />
        )}

        <div className={styles.dropdownToggle}>
          <h5 className={styles.heading}>
            {isLoggedIn ? (
              <div className={styles.name}>hi, {user.first_name}</div>
            ) : (
              'Log In or Sign Up'
            )}
          </h5>
        </div>
      </button>
      {isAuthMenuOpen && (
        <SlideInDown
          transformFrom="16px"
          className={classNames(styles.dropdownMenu, {
            [styles.withMembership]: canShowCustomerMembershipBanner,
            [styles.isMarketplace]: isMarketplacePlatform,
          })}
        >
          {isLoggedIn && (
            <ul>
              {canShowCustomerMembershipBanner && (
                <li>
                  <CustomerMembershipBanner
                    userMembershipPlan={
                      hasActiveMembership
                        ? userMembershipPlan
                        : lastExpiredMembership
                    }
                    onSelectRenew={handleSelectRenewMembership}
                  />
                </li>
              )}
              <li
                tabIndex={0}
                className={styles.listItem}
                data-testid="AccountSettings"
              >
                <Link
                  tabIndex={-1}
                  to={
                    isMarketplacePlatform
                      ? buildRoute({ route: ROUTES.account })
                      : buildAccountRoute({
                          query: {
                            name: 'company',
                            value: hqId || savedHqId,
                          },
                        })
                  }
                  className={styles.listLink}
                >
                  Account Settings
                </Link>
              </li>
              <li
                tabIndex={0}
                className={styles.listItem}
                data-testid="OrderHistory"
              >
                <Link
                  tabIndex={-1}
                  to={buildRoute({
                    route: ROUTES.history,
                    query: !isMarketplacePlatform && {
                      name: 'company',
                      value: hqId || savedHqId,
                    },
                  })}
                  className={styles.listLink}
                >
                  Order History
                </Link>
              </li>
              {isMarketplacePlatform && (
                <li className={styles.listItem}>
                  <Outlink
                    to={HELP_URL}
                    ariaLabel="Customer Support"
                    className={styles.listLink}
                    handleClick={onSupportClick}
                  >
                    Help
                  </Outlink>
                </li>
              )}
              <li
                tabIndex={0}
                onClick={handleClickLogout}
                onKeyDown={(e) => {
                  if (e.key === KEY_VALUES.enter) handleClickLogout();
                }}
                className={styles.listItem}
                data-testid="Logout"
              >
                <a tabIndex={-1} className={styles.listLink}>
                  Log Out
                </a>
              </li>
              {isMarketplacePlatform && (
                <li
                  className={classNames(styles.listItem, styles.restaurantLink)}
                >
                  <Outlink
                    to={`${BASE_CHOWNOW_URL}/?utm_medium=desktop_auth_dropdown&utm_source=marketplace_web`}
                    ariaLabel="For Restaurants"
                    className={styles.listLink}
                  >
                    For Restaurants
                  </Outlink>
                </li>
              )}
            </ul>
          )}
          {!isLoggedIn && isMarketplacePlatform && (
            <ul>
              <li
                tabIndex={0}
                onClick={() => handleOpenAuthModal(AUTH_MODAL_TYPE.loginSignUp)}
                onKeyDown={(e) => {
                  if (e.key === KEY_VALUES.enter)
                    handleOpenAuthModal(AUTH_MODAL_TYPE.loginSignUp);
                }}
                className={styles.listItem}
              >
                <a tabIndex={-1} className={styles.listLink}>
                  Log In or Sign Up
                </a>
              </li>
              <li
                className={classNames(styles.listItem, styles.restaurantLink)}
              >
                <Outlink
                  to={`${BASE_CHOWNOW_URL}/?utm_medium=desktop_auth_dropdown&utm_source=marketplace_web`}
                  ariaLabel="For Restaurants"
                  className={styles.listLink}
                >
                  For Restaurants
                </Outlink>
              </li>
              <li className={styles.listItem}>
                <Outlink
                  to="https://admin.chownow.com/admin/login?next=https%3A%2F%2Fdashboard.chownow.com"
                  ariaLabel="Restaurant Log In"
                  className={styles.listLink}
                >
                  Restaurant Log In
                </Outlink>
              </li>
            </ul>
          )}
        </SlideInDown>
      )}
      <div
        data-testid="AuthMenuOverlay"
        className={classNames(styles.dropdownOverlay, {
          [styles.visible]: isAuthMenuOpen,
        })}
        onClick={toggleDropdown}
        aria-hidden
      />
    </div>
  );
}

export default AuthMenu;
