import React from 'react';

export default function StickerPlaceholder() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="55"
        height="55"
        rx="27.5"
        stroke="black"
        strokeDasharray="2 2"
      />
    </svg>
  );
}
