/* eslint-disable complexity */
import {
  get,
  isEmpty,
  isUndefined,
  capitalize,
  remove,
  cloneDeep,
} from 'lodash';
import { getDateForApiDateStr } from '@chownow/cn-web-utils/date';
import { startOfSecond, compareDesc, format, isToday } from 'date-fns';

import { getIsSessionStorageAllowed } from 'helpers/customer';

export const FULFILLMENT_METHODS = {
  curbside: 'curbside',
  delivery: 'delivery',
  dineIn: 'dine_in',
  pickup: 'pickup',
};

export const ORDER_WHEN_OPTIONS = {
  later: {
    label: 'Order Ahead',
    // Indicates the order is for later but user needs to pick date & time
    value: undefined,
  },
  now: {
    label: 'ASAP',
    // The CN API represents orders for now by passing `null` for `when`
    value: null,
  },
};

export const DISCOUNT_TYPES = {
  discount: 'automatic',
  promo: 'promo_code',
};

export const PROCESSED_ORDER_STATUSES = [
  'Submitted',
  'Accepted',
  'Canceled',
  'Declined',
];

export const ORDER_STATUSES = {
  submitted: PROCESSED_ORDER_STATUSES[0],
  accepted: PROCESSED_ORDER_STATUSES[1],
  canceled: PROCESSED_ORDER_STATUSES[2],
  declined: PROCESSED_ORDER_STATUSES[3],
  completed: 'Completed', // front-end only status
};

export const COMPLETED_ORDER_STATUSES = ['Submitted', 'Accepted'];

export function flattenNestedModifiers(userModCatPayload, nestedList) {
  if (!userModCatPayload) {
    return;
  }

  if (userModCatPayload) {
    userModCatPayload.forEach((modCat) => {
      const topLevelModifiers = modCat.modifiers || modCat.selections;
      topLevelModifiers.forEach((modifier) => {
        nestedList.push(modifier.id);
        if (modifier.modifier_categories?.length) {
          flattenNestedModifiers(modifier.modifier_categories, nestedList);
        }
      });
    });
  }
}

// Returns a list of flattened ids
export function getFlatModIds(modCategories) {
  const flatIds = [];
  flattenNestedModifiers(modCategories, flatIds);
  return flatIds;
}

export function calculateModifierPrice(userModCatPayload, modifierData) {
  const modifiers = getFlatModIds(userModCatPayload);
  return modifiers.reduce((previousPrice, modifierId) => {
    const modifier =
      modifierData[modifierId] ||
      modifierData.find((mod) => mod.id === modifierId) ||
      {};
    return previousPrice + modifier.price;
  }, 0);
}

export function calculateNumItems(items = []) {
  return items.reduce(
    (previousQuantity, item) => previousQuantity + item.quantity,
    0
  );
}

// TODO: look into using this helper app wide
export function calculateTotalCredits(credits = []) {
  return credits.reduce(
    (totalCredits, credit) => totalCredits + credit.amount,
    0
  );
}

export function getOrderReadyTime(order) {
  if (!order) return null;
  const { fulfillment_times: fulfillmentTimes } = order;

  const endTime =
    get(fulfillmentTimes, 'estimated_arrival') ||
    get(fulfillmentTimes, 'estimated_pickup') ||
    get(fulfillmentTimes, 'requested_fulfillment');

  return getDateForApiDateStr(endTime);
}

export function getIsOrderFulfilled(order) {
  const orderReadyTime = getOrderReadyTime(order);
  const isOrderReadyTimeExpired = compareDesc(
    startOfSecond(new Date()),
    orderReadyTime
  );

  return !!(
    orderReadyTime &&
    isOrderReadyTimeExpired <= 0 &&
    order.status !== ORDER_STATUSES.canceled
  );
}

export function getSubmitOrderCount() {
  const isSessionStorageAllowed = getIsSessionStorageAllowed();
  let count = '';

  if (isSessionStorageAllowed) {
    count = JSON.parse(sessionStorage.getItem('submitOrderCount'));
  }

  return count;
}

export function setSubmitOrderCount(cardId) {
  const isSessionStorageAllowed = getIsSessionStorageAllowed();
  const submitOrderCount = getSubmitOrderCount();
  const count = submitOrderCount ? submitOrderCount.count + 1 : 1;

  const ids = submitOrderCount ? submitOrderCount.ids : [];
  if (!ids.includes(cardId)) {
    ids.push(cardId);
  }

  const newOrderCount = { count, ids };
  if (isSessionStorageAllowed) {
    sessionStorage.setItem('submitOrderCount', JSON.stringify(newOrderCount));
  }
}

export function resetSubmitOrderCount(cardId) {
  const isSessionStorageAllowed = getIsSessionStorageAllowed();
  const submitOrderCount = getSubmitOrderCount();

  if (!submitOrderCount) return;

  const wasCardPreviouslyDeclined = !!submitOrderCount.ids.includes(cardId);
  if (!wasCardPreviouslyDeclined) return;

  let ids = submitOrderCount.ids || [];
  ids = ids.filter((item) => item !== cardId);

  const newOrderCount = { count: submitOrderCount.count, ids };
  if (isSessionStorageAllowed) {
    sessionStorage.setItem('submitOrderCount', JSON.stringify(newOrderCount));
  }
}

export function setTriggerPostPurchaseModal() {
  const isSessionStorageAllowed = getIsSessionStorageAllowed();

  if (isSessionStorageAllowed) {
    sessionStorage.setItem('triggerPostPurchaseModal', true);
  }
}

export function getTriggerPostPurchaseModal() {
  const isSessionStorageAllowed = getIsSessionStorageAllowed();

  let trigger = '';

  if (isSessionStorageAllowed) {
    trigger = JSON.parse(sessionStorage.getItem('triggerPostPurchaseModal'));
  }

  return trigger;
}

export function removeTriggerPostPurchaseModal() {
  const isSessionStorageAllowed = getIsSessionStorageAllowed();

  if (isSessionStorageAllowed) {
    sessionStorage.removeItem('triggerPostPurchaseModal');
  }
}

export function getAppliedPromo(discounts) {
  if (isEmpty(discounts)) return;

  // if there are discounts on the order, check for autoapply && not membership
  // eslint-disable-next-line consistent-return
  return discounts.filter(
    (promo) =>
      promo.type === 'automatic' && !promo.is_membership && !promo.is_reward
  );
}

export function getEstimationText(fulfillMethod, isOrderAhead) {
  // TODO: Need dine-in cases
  const isDelivery = fulfillMethod === FULFILLMENT_METHODS.delivery;
  // Curbside and Pickup use same copy
  const fulfillmentMethod = isDelivery
    ? FULFILLMENT_METHODS.delivery
    : FULFILLMENT_METHODS.pickup;
  return `${isOrderAhead ? 'Scheduled' : 'Estimated'} ${capitalize(
    fulfillmentMethod
  )} Time:`;
}

export function getOrderTimeEstimate(orderReadyTime) {
  const hasOrderReadyTime = !!orderReadyTime;
  const isOrderForToday = isToday(orderReadyTime);
  const orderReadyTimeFormat = isOrderForToday
    ? 'h:mmaaa'
    : "EEE, MMM d 'at' h:mmaaa";

  if (hasOrderReadyTime) {
    return format(orderReadyTime, orderReadyTimeFormat);
  }

  return 'Pending...';
}

export function getNestedSelections(currentPath, modCategories) {
  let currentArray = modCategories;

  for (let i = 0; i < currentPath.length; i += 1) {
    const found = currentArray?.find((item) => item.id === currentPath[i]);
    if (found) {
      if (i % 2 === 0) {
        // If index is even, then it's a modCategory id
        // 'selections' is returned from API
        // 'modifiers' is sent to API + how we store in FE
        currentArray = found.selections || found.modifiers;
      } else {
        // If index is odd, then it's a modifier id
        currentArray = found.modifier_categories;
      }
    } else {
      currentArray = [];
    }
  }

  return currentArray;
}

function recursion(index, selections, currentPath, isRadio, shouldRemoveMod) {
  // FIXME: do we want this here?
  const MAX_INDEX = 8;

  if (index === MAX_INDEX) return;

  selections.forEach((obj) => {
    const newArray = obj.modifiers || obj.modifier_categories;

    if (obj.id === currentPath[index]) {
      // FIXME: do we have to do this every time?
      const objToRemove = newArray.find(
        (mod) => mod.id === currentPath[currentPath.length - 1]
      );

      if (shouldRemoveMod && objToRemove) {
        remove(
          obj.modifiers,
          (mod) => mod.id === currentPath[currentPath.length - 1]
        );
      } else if (
        newArray.find(
          (modOrModCat) => modOrModCat.id === currentPath[index + 1]
        )
      ) {
        recursion(index + 1, newArray, currentPath, isRadio, shouldRemoveMod);
        // Are we in a modifier category object
      } else if (index % 2 === 0) {
        if (isRadio) {
          obj.modifiers.pop();
        }
        obj.modifiers.push({
          id: currentPath[currentPath.length - 1],
          modifier_categories: [],
        });
      } else {
        obj.modifier_categories.push({
          id: currentPath[index + 1],
          modifiers: [
            {
              id: currentPath[index + 2],
              modifier_categories: [],
            },
          ],
        });
      }
    }
  });
}

export function getUpdatedSelections({
  currentPath,
  selections,
  isRadio = false,
  shouldRemoveMod = false,
}) {
  const updatedSelections = cloneDeep(selections);
  // If currentPath is only 2, that means it's a root modifier
  if (
    currentPath.length === 2 &&
    !updatedSelections.find((modCat) => modCat.id === currentPath[0])
  ) {
    updatedSelections.push({
      id: currentPath[0],
      modifiers: [
        {
          id: currentPath[1],
          modifier_categories: [],
        },
      ],
    });
  } else {
    recursion(0, updatedSelections, currentPath, isRadio, shouldRemoveMod);
  }

  return updatedSelections;
}

export function getRepeatedItem(item, orderItems) {
  const orderItemsWithSameIds = orderItems.filter((orderItem) => {
    const isSameId = item.id === orderItem.id;
    const isSameSize =
      item.sizeId === orderItem.sizeId ||
      item.id === orderItem.sizeId ||
      (item.id === item.sizeId && !orderItem.size);
    return isSameId && isSameSize && item.tracking_id !== orderItem.tracking_id;
  });

  const orderItemsWithSameIdsAndInstructions = orderItemsWithSameIds.filter(
    (orderItem) => {
      const itemHasInstructions = item.specialInstructions !== '';
      const orderItemHasInstructions = orderItem.specialInstructions !== '';
      const isSameSpecialInstructions =
        item.specialInstructions?.trim() ===
          orderItem.specialInstructions?.trim() ||
        (!itemHasInstructions && !orderItemHasInstructions);
      return isSameSpecialInstructions;
    }
  );

  const repeatItem = orderItemsWithSameIdsAndInstructions.find((orderItem) => {
    const orderModCats = orderItem.modifierCategories;
    const itemModCats = item.modifierCategories;
    // in the case of recently ordered comparing to regular menu
    if (orderModCats.length !== itemModCats.length) {
      const edittedOrderModCats = orderModCats.filter(
        (modCat) => modCat.modifiers.length !== 0
      );
      return (
        JSON.stringify(edittedOrderModCats) === JSON.stringify(itemModCats)
      );
    }
    return JSON.stringify(orderModCats) === JSON.stringify(itemModCats);
  });

  return repeatItem;
}

export function mapIdToName(modifierId, allModifiers) {
  const mappedMod = allModifiers.find((mod) => mod.id === modifierId);

  return mappedMod.name || '';
}

export function getModifierNames(orderItemModCategories, allModifiers) {
  const modifierNames = [];

  orderItemModCategories.forEach((item) => {
    // Data may come back with either 'modifiers' or 'selections'
    const topLevelModifiers = item.modifiers || item.selections;
    if (topLevelModifiers) {
      topLevelModifiers.forEach((modifier) => {
        const modName = mapIdToName(modifier.id, allModifiers);
        if (modifier.modifier_categories?.length) {
          const nestedIds = getFlatModIds(modifier.modifier_categories);
          const nestedNames = nestedIds.map((id) =>
            mapIdToName(id, allModifiers)
          );
          const childrenMods = nestedNames.length
            ? ` (${nestedNames.join(', ')})`
            : '';
          const nestedMod = `${modName}${childrenMods}`;
          modifierNames.push(nestedMod);
        } else {
          modifierNames.push(modName);
        }
      });
    }
  });

  return modifierNames;
}

export function hasChanged(prevValue, newValue) {
  return (
    !isUndefined(prevValue) && !isUndefined(newValue) && prevValue !== newValue
  );
}
