/* eslint-disable complexity */

// ticket to fix the below linting error here: https://chownow.atlassian.net/browse/CN-23035
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSpring, animated } from 'react-spring';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import { get } from 'lodash';

import { timeOfDay } from '@chownow/cn-web-utils/date';
import {
  BASE_CHOWNOW_URL,
  DOWNLOAD_APP_URL,
  HELP_URL,
} from '@chownow/cn-web-utils/paths';
import { getDeviceType } from '@chownow/cn-web-utils/url';
import { buildRoute, buildAccountRoute, ROUTES } from 'helpers/routes';
import { MODAL_TYPE, AUTH_MODAL_TYPE } from 'helpers/modals';

import {
  logAnalyticsEvent,
  ANALYTICS_EVENT_NAME,
  handleAppLinkClick,
} from 'helpers/loggers';
import { isWithin30Days } from 'helpers/date';
import { KEY_VALUES } from 'helpers/constants';
import { getRestaurantAppUrl, isOnLocations, isOnPage } from 'helpers/url';

import usePlatform from 'hooks/usePlatform';

import { getRestaurantDetails } from 'modules/restaurant';

import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import MembershipBadge from 'components/MembershipBadge';
import CustomerMembershipBanner from 'components/CustomerMembershipBanner';
import Outlink from 'primitives/Outlink';

import { ReactComponent as IconHamburger } from 'images/icon-hamburger.svg';
import { ReactComponent as IconClose } from 'images/icon-close.svg';
import { ReactComponent as IconApp } from 'images/icon-app-logo.svg';
import { ReactComponent as IconPoweredBy } from 'images/icon-poweredby.svg';

import styles from './styles.module.scss';

function SideMenu(props) {
  const { isMultiLocation, onLocationsBack } = props;
  const defaultProps = { transform: 'translateX(100%)', opacity: 0 };

  const { hqId, restaurantId } = useParams();
  const sideMenuListRef = useRef(null);

  const { openModal } = useContext(ModalContext);
  const {
    isLoggedIn,
    user,
    handleLogout,
    membership = {},
  } = useContext(UserContext);
  const { userMembershipPlan, hasActiveMembership, expiredMemberships } =
    membership;

  const { isMarketplacePlatform } = usePlatform();

  const device = getDeviceType();
  const restaurant = useSelector(getRestaurantDetails);
  const restaurantAppUrl = getRestaurantAppUrl(device, restaurant);

  const isLocations = isOnLocations();
  const isMembership = isOnPage('membership') && !isOnPage('checkout'); // membership plans and membership confirmation

  // if they have an active membership we want that to show up always, otherwise only show expired
  // membership for 30 days after
  const lastExpiredMembership =
    expiredMemberships && expiredMemberships[expiredMemberships.length - 1];
  const canShowCustomerMembershipBanner =
    (hasActiveMembership ||
      isWithin30Days(get(lastExpiredMembership, 'expires_at'))) &&
    !isMarketplacePlatform;

  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  const [springProps, set] = useSpring(() => ({
    ...defaultProps,
    config: isSideMenuOpen
      ? { friction: 28, tension: 250 }
      : { friction: 38, tension: 350 },
  }));

  const SIDENAV_UTM =
    '?utm_source=product&utm_medium=orderdirect&utm_campaign=poweredbysidenav';

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => clearAllBodyScrollLocks();
  }, []);

  useEffect(() => {
    if (isSideMenuOpen) {
      set({ transform: 'translateX(0)', opacity: 1 });
      disableBodyScroll(sideMenuListRef.current);
    } else {
      set(defaultProps);
      enableBodyScroll(sideMenuListRef.current);
    }
  }, [isSideMenuOpen]);

  function handleClickLogout() {
    handleLogout();
    setIsSideMenuOpen(false);
  }

  function handleAuth(authModalType) {
    setIsSideMenuOpen(false);

    openModal(MODAL_TYPE.auth, { activeAuthModal: authModalType });
  }

  function handleLocationsBack() {
    setIsSideMenuOpen(false);
    onLocationsBack();
  }

  function getDownloadUrl() {
    if (isMarketplacePlatform) {
      return (
        <p className={styles.orderCopy}>
          Love ordering local?{' '}
          <a
            className={styles.orderLink}
            href={DOWNLOAD_APP_URL}
            onClick={() => {
              handleAppLinkClick({
                isNativeApp: false,
                source: 'side_menu',
              });
            }}
          >
            Get the app
          </a>
        </p>
      );
    }

    if (restaurantAppUrl) {
      return (
        <p className={styles.orderCopy}>
          Love ordering with us?{' '}
          <a
            className={styles.orderLink}
            href={restaurantAppUrl}
            onClick={() => {
              handleAppLinkClick({
                isNativeApp: true,
                source: 'side_menu',
              });
            }}
          >
            Get the app
          </a>
        </p>
      );
    }

    return null;
  }

  function onSupportClick() {
    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.selectCustomerSupport,
      attributes: {
        source: 'side-panel',
      },
    });
    // flushes the event queue and forces the event to send to mParticle
    // only needed when linking out to prevent CORS error
    window.mParticle.upload();
  }

  return (
    <>
      <button
        onClick={() => setIsSideMenuOpen(true)}
        className={styles.hamburgerIconBtn}
        data-testid="LoginUserMobile"
        type="button"
      >
        <IconHamburger className={styles.hamburgerIcon} alt="view side menu" />
      </button>
      <animated.div
        style={{
          opacity: springProps.opacity,
        }}
        onClick={() => setIsSideMenuOpen(false)}
        className={classNames(styles.overlay, {
          [styles.touchable]: isSideMenuOpen,
        })}
      />
      <animated.div
        style={{
          transform: springProps.transform,
        }}
        className={styles.sideMenuWrap}
      >
        <div className={styles.sideMenu} data-testid="SideMenu">
          <button
            type="button"
            className={styles.closeIcon}
            onClick={() => setIsSideMenuOpen(false)}
          >
            <IconClose />
          </button>
          <div className={styles.header}>
            <div className={styles.membershipStatus}>
              {hasActiveMembership && !isMarketplacePlatform && (
                <MembershipBadge userMembershipPlan={userMembershipPlan} />
              )}
              <h3 className={styles.greeting} data-testid="SideMenuGreeting">
                <div className={styles.greetingText}>
                  Good {timeOfDay().toLowerCase()}
                  {isLoggedIn && (
                    <>
                      <span>,</span>
                      <div>
                        <span title={user.first_name}>{user.first_name}</span>
                      </div>
                    </>
                  )}
                </div>
              </h3>
            </div>
            {canShowCustomerMembershipBanner && (
              <CustomerMembershipBanner
                userMembershipPlan={
                  hasActiveMembership
                    ? userMembershipPlan
                    : lastExpiredMembership
                }
              />
            )}
          </div>
          <div className={styles.linkWrap} ref={sideMenuListRef}>
            <ul>
              {isMarketplacePlatform && (
                <li className={styles.listItem} data-testid="Home">
                  <a className={styles.listLink} href="/discover">
                    Home
                  </a>
                </li>
              )}

              {!isLocations &&
                isMultiLocation &&
                !isMarketplacePlatform &&
                !!hqId && (
                  <li
                    className={styles.listItem}
                    onClick={handleLocationsBack}
                    onKeyDown={(e) => {
                      if (e.key === KEY_VALUES.enter) handleLocationsBack();
                    }}
                  >
                    Locations
                  </li>
                )}
              {isMembership && (
                <li className={styles.listItem}>
                  <Link
                    to={buildRoute({
                      hqId,
                      restaurantId,
                      route: ROUTES.menu,
                    })}
                    className={styles.listLink}
                  >
                    Menu
                  </Link>
                </li>
              )}
              {isLoggedIn ? (
                <>
                  <li className={styles.listItem} data-testid="AccountMobile">
                    <Link
                      to={
                        isMarketplacePlatform
                          ? buildRoute({ route: ROUTES.account })
                          : buildAccountRoute({
                              query: {
                                name: 'company',
                                value: hqId,
                              },
                            })
                      }
                      className={styles.listLink}
                    >
                      Account Settings
                    </Link>
                  </li>

                  <li className={styles.listItem} data-testid="HistoryMobile">
                    <Link
                      to={buildRoute({
                        route: ROUTES.history,
                        query: !isMarketplacePlatform && {
                          name: 'company',
                          value: hqId,
                        },
                      })}
                      className={styles.listLink}
                    >
                      Order History
                    </Link>
                  </li>
                  {isMarketplacePlatform && (
                    <li className={styles.listItem} data-testid="HelpMobile">
                      <Outlink
                        to={HELP_URL}
                        ariaLabel="Customer Support"
                        className={styles.listLink}
                        handleClick={onSupportClick}
                      >
                        Help
                      </Outlink>
                    </li>
                  )}

                  <li
                    onClick={handleClickLogout}
                    onKeyDown={(e) => {
                      if (e.key === KEY_VALUES.enter) handleClickLogout();
                    }}
                    className={styles.listItem}
                  >
                    Log Out
                  </li>

                  {isMarketplacePlatform && (
                    <li
                      className={classNames(
                        styles.listItem,
                        styles.restaurantLink
                      )}
                    >
                      <Outlink
                        to={`${BASE_CHOWNOW_URL}/?utm_medium=mobile_side_menu&utm_source=marketplace_web`}
                        ariaLabel="For Restaurants"
                        className={styles.listLink}
                      >
                        For Restaurants
                      </Outlink>
                    </li>
                  )}
                </>
              ) : (
                <>
                  <li
                    className={styles.listItem}
                    onClick={() => handleAuth(AUTH_MODAL_TYPE.loginSignUp)}
                    onKeyDown={(e) => {
                      if (e.key === KEY_VALUES.enter)
                        handleAuth(AUTH_MODAL_TYPE.loginSignUp);
                    }}
                    data-testid="MobileSignUp"
                  >
                    Log In or Sign Up
                  </li>
                  {isMarketplacePlatform && (
                    <>
                      <li
                        className={classNames(
                          styles.listItem,
                          styles.restaurantLink
                        )}
                      >
                        <Outlink
                          to={`${BASE_CHOWNOW_URL}/?utm_medium=mobile_side_menu&utm_source=marketplace_web`}
                          ariaLabel="For Restaurants"
                          className={styles.listLink}
                        >
                          For Restaurants
                        </Outlink>
                      </li>
                      <li className={styles.listItem}>
                        <Outlink
                          to="https://admin.chownow.com/admin/login?next=https%3A%2F%2Fdashboard.chownow.com"
                          ariaLabel="Restaurant Log In"
                          className={styles.listLink}
                        >
                          Restaurant Log In
                        </Outlink>
                      </li>
                    </>
                  )}
                </>
              )}
            </ul>
          </div>
          <div className={styles.downloadWrapper}>
            <div className={styles.appLinkWrapper}>
              {isMarketplacePlatform && (
                <div className={styles.appLogoWrapper}>
                  <Outlink
                    to={DOWNLOAD_APP_URL}
                    ariaLabel="Download the ChowNow App"
                    handleClick={() => {
                      handleAppLinkClick({
                        isNativeApp: false,
                        source: 'side_menu',
                      });
                    }}
                  >
                    <IconApp aria-hidden="true" />
                  </Outlink>
                </div>
              )}
              {getDownloadUrl()}
            </div>
            {!isMarketplacePlatform && (
              <div className={styles.iconsWrapper}>
                <Outlink
                  to={`${BASE_CHOWNOW_URL}/${SIDENAV_UTM}`}
                  ariaLabel="About ChowNow"
                >
                  <IconPoweredBy />
                </Outlink>
              </div>
            )}
          </div>
        </div>
      </animated.div>
    </>
  );
}

SideMenu.propTypes = {
  isMultiLocation: PropTypes.bool,
  onLocationsBack: PropTypes.func,
};

SideMenu.defaultProps = {
  isMultiLocation: false,
  onLocationsBack: () => {},
};

export default SideMenu;
