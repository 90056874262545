import React from 'react';

import EarnedSticker from 'svgs/EarnedSticker';
import StickerPlaceholder from 'svgs/StickerPlaceholder';

const stickerColors = ['green', 'yellow', 'pink'];

export const getEarnedStickers = (count) =>
  Array.from({ length: count }, (_, index) => (
    <EarnedSticker
      key={`earnedSticker_${index}`}
      color={stickerColors[index % stickerColors.length]}
    />
  ));

export const getPlaceholderStickers = (count) =>
  Array.from({ length: count }, (_, index) => (
    <StickerPlaceholder key={`placeholderSticker_${index}`} />
  ));
