/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useState, useMemo, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import breakpoints from 'helpers/breakpoints';
import { logAnalyticsEvent, ANALYTICS_EVENT_NAME } from 'helpers/loggers';
import { getSourcePage, MODAL_TYPE } from 'helpers/modals';
import { getPopupPosition } from 'helpers/yelp';

import usePlatform from 'hooks/usePlatform';

import AccountModal from 'components/ModalWrapper/AccountModal';
import AuthSharedModal from 'components/ModalWrapper/AuthSharedModal';
import { BagModal } from 'components/ModalWrapper/BagModal';
import CategoryModal from 'components/ModalWrapper/CategoryModal';
import ConfirmAccountModal from 'components/ModalWrapper/ConfirmAccountModal';
import DialogModal from 'components/ModalWrapper/DialogModal';
import DownloadAppModal from 'components/ModalWrapper/DownloadAppModal';
import FulfillmentDetailsModal from 'components/ModalWrapper/FulfillmentDetailsModal';
import HelpModal from 'components/ModalWrapper/HelpModal';
import HoursModal from 'components/ModalWrapper/HoursModal';
import ImpactScoreModal from 'components/ModalWrapper/ImpactScoreModal';
import ItemExpiredModal from 'components/ModalWrapper/ItemExpiredModal';
import ModalWrapper from 'components/ModalWrapper';
import MenuItemModal from 'components/ModalWrapper/MenuItemModal';
import OrderingUnavailableModal from 'components/ModalWrapper/OrderingUnavailableModal';
import PaymentModal from 'components/ModalWrapper/PaymentModal';
import PollingModal from 'components/ModalWrapper/PollingModal';
import PostPurchaseImpactModal from 'components/ModalWrapper/PostPurchaseImpactModal';
import PromoModal from 'components/ModalWrapper/PromoModal';
import RewardsModal from 'components/ModalWrapper/RewardsModal';
import RewardsOptOutModal from 'components/ModalWrapper/RewardsOptOutModal';
import TableModal from 'components/ModalWrapper/TableModal';
import TimeUnavailableModal from 'components/ModalWrapper/TimeUnavailableModal';
import TipModal from 'components/ModalWrapper/TipModal';
import TipAdjustmentModal from 'components/ModalWrapper/TipAdjustmentModal';

import ModalWrapperStyles from 'components/ModalWrapper/styles.module.scss';

const MODAL_COMPONENTS = {
  ACCOUNT: AccountModal,
  AUTH_SHARED: AuthSharedModal,
  BAG: BagModal,
  CATEGORY: CategoryModal,
  CONFIRM_ACCOUNT: ConfirmAccountModal,
  DIALOG: DialogModal,
  DOWNLOAD_APP: DownloadAppModal,
  FULFILLMENT_DETAILS: FulfillmentDetailsModal,
  HELP: HelpModal,
  HOURS: HoursModal,
  IMPACT_SCORE: ImpactScoreModal,
  ITEM_EXPIRED: ItemExpiredModal,
  REWARDS: RewardsModal,
  REWARDS_OPTOUT: RewardsOptOutModal,
  MENU_ITEM: MenuItemModal,
  ORDERING_UNAVAILABLE: OrderingUnavailableModal,
  PAYMENT: PaymentModal,
  POLLING: PollingModal,
  POST_PURCHASE_IMPACT: PostPurchaseImpactModal,
  PROMO: PromoModal,
  TABLE: TableModal,
  TIME_UNAVAILABLE: TimeUnavailableModal,
  TIP_ADJUSTMENT: TipAdjustmentModal,
  TIP: TipModal,
};

interface ModalProviderProps {
  children: React.ReactNode;
}

export type ModalContextProps = {
  isModalOpen: boolean;
  openModal: (component: string, props?: object) => void;
  closeModal: () => void;
};

const ModalContext = createContext<ModalContextProps>({
  isModalOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openModal: (componentType, props) => {},
  closeModal: () => {},
});

function ModalProvider({ children }: ModalProviderProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [component, setComponent] = useState('');
  const [componentProps, setComponentProps] = useState({});

  const location = useLocation();
  const { isYelpPlatform } = usePlatform();

  const ModalComponent =
    MODAL_COMPONENTS[component as keyof typeof MODAL_COMPONENTS];

  const isMobile = window.innerWidth < breakpoints.xsMax;
  const path = location.pathname;
  const sourcePageArgs = {
    path,
    isMobile,
  };

  // When running the Yelp platform, we have to measure the height of the modal dialog
  // being displayed, and send a message to Yelp.  In return, they will tell us what the
  // top value should be (where the dialog should be positioned relative to the top of
  // the iframe the app is rendered within).
  // Ref:  https://docs.developer.yelp.com/docs/iframe-api-specification#popup-interactions
  useEffect(() => {
    if (isYelpPlatform && isModalOpen) {
      const modalElement = document.querySelector(
        `.${ModalWrapperStyles.modalWrapper}`
      );

      if (modalElement && modalElement.clientHeight) {
        getPopupPosition(modalElement.clientHeight);
      }
    }
  }, [isModalOpen]);

  function openModal(componentType: string, props?: object) {
    // do not attempt to open a different modal if AUTH is currently open
    if (!componentType || component === MODAL_TYPE.auth) return;

    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.openModal,
      attributes: {
        modal_type: `${componentType}`,
        source: getSourcePage(sourcePageArgs),
      },
    });

    document.body.style.overflowY = 'hidden'; // no scrolling behind modal
    setComponent(componentType);
    setComponentProps(props || {});
    setIsModalOpen(true);
  }

  function closeModal() {
    const shouldLogCloseModalEvent = !isYelpPlatform;

    if (shouldLogCloseModalEvent) {
      logAnalyticsEvent({
        eventName: ANALYTICS_EVENT_NAME.closeModal,
        attributes: {
          source: getSourcePage(sourcePageArgs),
          modal_type: `${component}`,
        },
      });
    }

    document.body.style.overflowY = ''; // enable scrolling
    setComponent('');
    setComponentProps({});
    setIsModalOpen(false);
  }

  useEffect(() => {
    if (location.pathname && isModalOpen) {
      closeModal();
    }
  }, [path]);

  const values = useMemo(
    () => ({
      isModalOpen,
      openModal,
      closeModal,
    }),
    [isModalOpen]
  );

  return (
    <ModalContext.Provider value={values}>
      {children}
      {isModalOpen && ModalComponent && (
        <ModalWrapper>
          <ModalComponent {...componentProps} />
        </ModalWrapper>
      )}
    </ModalContext.Provider>
  );
}

export { ModalContext, ModalProvider };
