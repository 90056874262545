import React from 'react';

export default function RewardPlaceholder() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" fill="#BCEFC4" />
      <rect
        x="0.5"
        y="0.5"
        width="55"
        height="55"
        rx="27.5"
        stroke="black"
        strokeDasharray="2 2"
      />
      <path
        d="M6.01129 25H8.64129C9.31462 25 9.85462 25.1833 10.2613 25.55C10.668 25.9167 10.8713 26.4033 10.8713 27.01C10.8713 27.4233 10.7646 27.79 10.5513 28.11C10.3446 28.43 10.058 28.67 9.69129 28.83L11.6113 32H10.2413L8.49129 29.03H7.19129V32H6.02129L6.01129 25ZM8.47129 28C8.85796 28 9.15462 27.9133 9.36129 27.74C9.57462 27.5667 9.68129 27.3233 9.68129 27.01C9.68129 26.6967 9.57462 26.4533 9.36129 26.28C9.15462 26.1067 8.85796 26.02 8.47129 26.02H7.19129V28H8.47129ZM13.2273 25H17.7973V26.02H14.3973V27.78H17.1373V28.8H14.3973V30.97H17.9073V32H13.2273V25ZM19.2493 25H20.4593L21.9393 30.62L23.4493 25H24.7393L26.2393 30.62L27.7293 25H28.9393L26.8793 32H25.6393L24.0993 26.66L22.5493 32H21.3293L19.2493 25ZM31.4859 29.34H33.6059L32.5459 26.54L31.4859 29.34ZM31.9959 25H33.0859L35.8159 32H34.6059L33.9859 30.35H31.1059L30.4859 32H29.2759L31.9959 25ZM37.3363 25H39.9663C40.6396 25 41.1796 25.1833 41.5863 25.55C41.993 25.9167 42.1963 26.4033 42.1963 27.01C42.1963 27.4233 42.0896 27.79 41.8763 28.11C41.6696 28.43 41.383 28.67 41.0163 28.83L42.9363 32H41.5663L39.8163 29.03H38.5163V32H37.3463L37.3363 25ZM39.7963 28C40.183 28 40.4796 27.9133 40.6863 27.74C40.8996 27.5667 41.0063 27.3233 41.0063 27.01C41.0063 26.6967 40.8996 26.4533 40.6863 26.28C40.4796 26.1067 40.183 26.02 39.7963 26.02H38.5163V28H39.7963ZM44.5523 25H46.7523C47.419 25 48.019 25.1333 48.5523 25.4C49.0923 25.6667 49.519 26.0633 49.8323 26.59C50.1523 27.1167 50.3123 27.7533 50.3123 28.5C50.3123 29.2467 50.1523 29.8833 49.8323 30.41C49.519 30.9367 49.0923 31.3333 48.5523 31.6C48.019 31.8667 47.419 32 46.7523 32H44.5523V25ZM46.6723 30.97C47.119 30.97 47.5256 30.8867 47.8923 30.72C48.259 30.5467 48.5523 30.2767 48.7723 29.91C48.999 29.5367 49.1123 29.0667 49.1123 28.5C49.1123 27.9333 48.999 27.4633 48.7723 27.09C48.5523 26.7167 48.259 26.4467 47.8923 26.28C47.5256 26.1067 47.119 26.02 46.6723 26.02H45.7023V30.97H46.6723Z"
        fill="black"
      />
    </svg>
  );
}
